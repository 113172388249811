@use "colors";
@use "typography";
@import "custom";

.club-info {
  min-block-size: 100vh;
  padding: 140px 0 50px;

  @include media-breakpoint-down(md) {
    max-inline-size: none;
    padding: 0 48px;
  }

  @include media-breakpoint-down(sm) {
    max-inline-size: none;
    padding: 48px 24px;
  }

  .inner {
    position: relative;
    transition: 0.2s ease-in-out;
  }

  .header {
    font-size: 64px;
  }

  .sub-header {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.4;
    margin-block-start: 50px;
  }

  .club-address {
    // placeholder
  }

  .club-manager {
    display: block;
  }

  .club-data-container {
    color: colors.$white;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .floor-plan-container {
    position: relative;

    &-upper {
      border-block-end: 1px solid colors.$off-black;
      padding: 14px 0;

      h3 {
        color: colors.$white;
        font-size: 20px;
        margin: 0;

        span {
          color: colors.$gray;
          font-size: 14px;
          margin-inline-start: 10px;
        }
      }
    }

    &-lower {
      inline-size: 100%;
      margin: 102px 0 0;

      @include media-breakpoint-down(md) {
        margin: 45px 0 0;
      }

      img {
        block-size: 100%;
        inline-size: 100%;
        object-fit: cover;
      }
    }
  }

  .accordion-container {
    color: colors.$gray;
    padding-inline-end: 50px;

    @include media-breakpoint-down(md) {
      padding: 56px 0 100px;
    }

    h3 {
      color: colors.$white;
      padding-block-end: 12px;
    }

    ul {
      list-style: none;
      padding: 0;

      .note {
        font-size: 12px;
      }

      li {
        display: flex;
        justify-content: space-between;

        &:last-of-type {
          margin-block-end: 20px;
          padding: 0;
        }

        label {
          display: block;
          margin: 0;
        }

        time {
          display: block;
          inline-size: auto;

          @include media-breakpoint-down(md) {
            inline-size: 65%;
            text-align: end;

            @media (orientation: landscape) {
              inline-size: 55%;
            }
          }

          @include media-breakpoint-down(sm) {
            inline-size: 55%;
            text-align: end;
          }

          @include media-breakpoint-down(xs) {
            inline-size: 65%;
          }
        }
      }
    }

    .holiday-exception {
      position: relative;

      strong {
        background-color: colors.$white;
        color: colors.$black;
        display: inline-block;
        font-size: 11px;
        inset-block-start: -1px;
        inset-inline-start: 50px;
        margin-inline-start: 15px;
        padding: 3px 8px;
        position: absolute;
      }
    }
  }

  .amenity-mobile-accordion {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  .amenities-container {
    color: colors.$gray;

    @include media-breakpoint-down(md) {
      padding-block-start: 56px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .amenities {
      border-block-start: solid colors.$off-black 1px;
      padding-block-start: 30px;

      h3 {
        @include typography.body;

        color: colors.$white;
        cursor: default;
        padding-block-end: 12px;
      }

      ul {
        @include typography.body-small;

        padding: 0 10px 10px 0;

        li {
          list-style-position: inside;
          margin-block-end: 20px;
          padding: 0;
        }
      }
    }
  }
}
