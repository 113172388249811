@use "colors";
@use "typography";
@import "custom";

.accordion {
  border-block-start: solid colors.$off-black 1px;
  outline: none;
  padding-block: 30px 20px;

  &:focus {
    &.black {
      color: colors.$white;

      button {
        border: 1px solid colors.$white !important;
      }
    }

    &.white {
      color: colors.$black;

      button {
        border: 1px solid colors.$black !important;
      }
    }
  }

  button {
    border: 1px solid transparent;
    border-radius: 50%;
    inset-block-start: -5px;
    padding: 3px 8px;
    position: absolute;

    svg {
      block-size: 20px;
      inline-size: 14px;
      inset-block-start: -2px;
      position: relative;
    }
  }

  &-contents {
    @include typography.body-small;

    max-inline-size: 85%;

    a {
      border-block-end: 1px solid #000;
    }

    @include media-breakpoint-down(sm) {
      max-inline-size: 90%;
    }
  }

  &-label {
    @include typography.body;

    display: inline-block;
    margin: 0;
    padding-block-end: 16px;
    user-select: none;

    @include media-breakpoint-down(sm) {
      line-height: 1.4;
      padding-inline-end: 30px;
    }

    &-wrap {
      cursor: pointer;
      position: relative;
    }

    &-arrow {
      background-color: transparent;
      border: none;
      display: block;
      inset-block-start: 0;
      inset-inline-end: 3px;
      outline: none !important;
      padding: 0;
      position: absolute;

      &-up,
      &-down {
        display: inline-block;
        transition: all 300ms;
      }

      &-up {
        transform: rotate(-180deg);
      }

      &-down {
        transform: rotate(0deg);
      }
    }
  }

  ul {
    list-style: none;
    margin: 10px 0 0;

    li {
      padding-block-end: 20px;
    }
  }
}
