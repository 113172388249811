@use "colors";
@use "typography";

.secondary-headline {
  @include typography.medium-headline;
}

.secondary-description {
  color: colors.$white;
  font-size: 20px;
}
